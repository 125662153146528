
 import  { atom, map, computed } from "nanostores"

import "./input-v1"
import "./select-box-v2"
import { resetInputErrors, addError } from '@yggdrasil/Checkout/V2/Utils/checkout-input-validator'
import { parseAddressByFields, fieldsForCountry } from '@yggdrasil/Checkout/V2/Utils/checkout-address'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutAddressFormV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}




  mount() {
    if (this.useCheckoutStore) {
      this.store = Checkout.store[this.type]
      this.fields = Checkout.store[`${this.type}Fields`]
      this.showAllErrors = Checkout.store.showAllErrors[this.type]
      this.formErrors = Checkout.computed[`${this.type}Errors`]
    } else {
      this.store = map({})
      this.fields = atom([...Checkout.store[`${this.type}Fields`].get()])
      this.showAllErrors = atom(false)
      this.formErrors = computed([this.store, this.showAllErrors], (store, showAllErrors) => {
        const fields = this.fields.get()
        return Checkout.utils.addressErrors(store, showAllErrors, fields)
      })

      // may still need to populate countries
      const initializeFromSavedContact = () => {
        const values = this.store.get()
        const fields = this.fields.get()
        const fieldsValues = parseAddressByFields(values, fields)
        this.store.set(fieldsValues)
        const { country, state } = this.store.get()
        if (country) { this.setInputValue('country', country) }
        if (state && this.hasState()) { this.setInputValue('state', state) }        
      }
      
      // Populate internal address object when checkout initialized on saved state
      Checkout.store.state.listen((newState) => {
        if (newState == Checkout.StoreStates.INITIALIZED) {          
          initializeFromSavedContact()
        }
      })
      Checkout.store.checkout.mode.listen(newMode => {
        if (newMode === 'saved') {
          initializeFromSavedContact()
        }
      })

      this.store.listen((address) => {
        const fields = this.fields.get()
        const addressFields = fieldsForCountry(fields, address.country)
        this.fields.set(addressFields)
      })
    }

    this.store.listen((fieldValues) => {
      this.fields.get().forEach((prop) => {
        this.setInputValue(prop, fieldValues[prop])
      })
    })
  
    this.formErrors.subscribe((errors) => {
      if (!this.disableInputValidationOnBlur) {
        this.checkErrors(errors)
      } else {
        if (this.showAllErrors.get()) this.checkErrors(errors)
      }
    })

    this.setupAutocomplete()
    this.setupInputListeners()
  }

  hasState() {
    return this.fields.get().includes('state')
  }

  hasPostalCode(fields) {
    return (fields ?? this.fields.get()).includes('zip')
  }

  checkErrors(errors) {
    const errorWrapper = this.element.querySelector('.elCheckoutFormErrors')
    errorWrapper.classList.add('elHide')
    this.fields.get().forEach((prop) => {
      const input = this.getInputFromName(prop)
      resetInputErrors(input)
    })

    if (Checkout.utils.hasErrors(errors)) {
      const fieldErrors = errors.fields
      if (fieldErrors) {
        const errors = []
        Object.entries(fieldErrors).forEach(([field, error]) => {
          const {message} = error
          errors.push(message)
          const input = this.getInputFromName(field)
          addError(input)
        })

        if (errors.length) {
          const listErrors = `<ul>${errors.map(err => `<li>${err}</li>`).join('')}</ul>`
          errorWrapper.innerHTML = listErrors
          errorWrapper.classList.remove('elHide')
        } else {
          errorWrapper.classList.add('elHide')
        }
      }
    }
  }

  getInputFromName(name) {
     return this.element.querySelector(`[name='${this.type}_${name}']`)
  }

  getWrapperElement(prop) {
    const input = this.getInputFromName(prop)
    return input.closest('.elFormItemWrapper')
  }

  setInputValue(fieldName, value) {
    const input = this.getInputFromName(fieldName)
    value = value?.trim()
    if (value) {
      if (fieldName === 'country') {
        !this.populatedCountries && this.populateCountries()
        this.populatedCountries = true
        if (this.hasState()) this.populateStates()
      }
      input.value = value
      $(input).parents('.elFormItemWrapper').addClass('hasValue')
    } else {
      input.value = ''
      $(input).parents('.elFormItemWrapper').removeClass('hasValue')
    }
  }

  setupAutocomplete() {
    if (!window.google?.maps?.places) return
    const addressInput = this.getInputFromName('address')
    if (!addressInput) return

    const autoComplete = new window.google.maps.places.Autocomplete(addressInput, {
      fields: ["address_components", "name"],
      types: ["address"],
    });

    const autoCompleteLsr = autoComplete.addListener("place_changed", () => {
      const place = autoComplete.getPlace();
    
      const valueByComponentType = place.address_components.reduce((acc, component) => {
        const type = component.types
        if (type.includes('locality')) {
          acc['locality'] = component.long_name
        } else if (type.includes('administrative_area_level_2')) {
          acc['administrative_area_level_2'] = component.long_name
        } else if (type.includes('administrative_area_level_1')) {
          acc['administrative_area_level_1'] = component.short_name
        } else if (type.includes('country')) {
          acc['country'] = component.short_name
        } else if (type.includes('postal_code')) {
          acc['postal_code'] = component.long_name
        }
        return acc
      }, {})

      const address = { address: place.name }
      address.country = valueByComponentType['country']
      const stateMap = this.stateMap(address.country)
      const stateValue = valueByComponentType['administrative_area_level_1']
      address.state = stateMap.mapByCode[stateValue] ? stateValue : stateMap.mapByGoogleName[stateValue]
      if (!address.state) {
        address.state = this.firstStateFromCountry(address.country)
      }

      address.city = valueByComponentType['locality'] || valueByComponentType['administrative_area_level_2']
      address.zip = valueByComponentType['postal_code']
      this.store.set(address)
      this.validateFormFields()
    })
    // NOTE: This is simple a way to prevent invalid Google API keys affecting address form
    // https://developers.google.com/maps/documentation/javascript/events#auth-errors
    if (!window.gm_authFailureSet) {
      window.gm_authFailure = () => {
        const inputTargets = document.querySelectorAll('.pac-target-input')
        inputTargets.forEach((input, index) => {
          
          input.disabled = false
          input.placeholder = 'Address'
          input.style.backgroundImage = ''
          if (window.prevCheckoutAddressFocus == input) {
            input.focus()
          }
        })
      }
      window.gm_authFailureSet = true
    }

    this.enableBrowserAutoCompleteWhenBlank(autoCompleteLsr)
  }

  updateFields(country) {
    const addressFields = fieldsForCountry(this.fields.get(), country)
    this.fields.set(addressFields)
  }

  setupInputListeners() {
    this.fields.get().forEach((prop) => {
      const input = this.getInputFromName(prop)
      if (input !== null) { 
        if (prop === 'state' || prop === 'country') {
          input.addEventListener('input', () => {
            if (prop === 'country') {
              const firstStateValue = this.firstStateFromCountry(input.value)
              // NOTE We populate the first state value from states, so that our store always
              // reflect whats being presented in the front-end
              if (this.hasState()) {
                this.store.set({
                  ...this.store.get(),
                  country: input.value,
                  state: firstStateValue
                })
              } else {
                this.store.set({
                  ...(this.hasPostalCode() ? this.store.get() : {}),
                  country: input.value,
                })
              }
            } else {
              const value = input.value
              // NOTE: On autofill we need to wait from the country to be set
              // before we can set the state this strategy ensures such behavior
              setTimeout(() => {
                this.store.setKey(prop, value)
              })
            }
          })
        } else {
          input.addEventListener('blur', () => {
            this.store.setKey(prop, input.value)
            // We need to re-validate form because input may not be changed,
            // and we removed the errors on focus.
            if (!this.disableInputValidationOnBlur) {
              this.checkErrors(this.formErrors.get())
            } else {
              if (this.showAllErrors.get()) this.checkErrors(this.formErrors.get())
            }
          })
        }
      }
    })

    this.fields.listen((fields) => {
      this.updatePostalCode(fields)
    })
    this.updatePostalCode()
  }

  updatePostalCode(fields) {
    const hasPostalCode = this.hasPostalCode(fields)
    const zipInputWrapper = this.getWrapperElement('zip')
    const countryWrapper = this.getWrapperElement('country')
    if (hasPostalCode) {
      zipInputWrapper.classList.remove('elHide')
      countryWrapper.classList.remove('elInputOuterBottomLeftCorner')
      countryWrapper.classList.remove('elInputOuterBottomRightCorner')
    } else {
      zipInputWrapper.classList.add('elHide')
      countryWrapper.classList.add('elInputOuterBottomLeftCorner')
      countryWrapper.classList.add('elInputOuterBottomRightCorner')
    }
  }

  validateFormFields() {
    const address = this.store.get()
    const errors = Checkout.utils.addressErrors(address, true, this.fields.get())
    if (Checkout.utils.hasErrors(errors)) {
      this.showAllErrors.set(true)
      return false
    } else {
      return true
    }
  }

  populateCountries() {
    const topMapping = ['US', 'CA', 'GB', 'IE', 'AU', 'NZ']
    const topHash = topMapping.reduce((acc, val) => {
      acc[val] = true
      return acc
    }, {})
    const topOptions = topMapping.map((iso2) => ({ iso2 }))
    const remainingOptions = []

    Checkout.allCountries.forEach((item) => {
      if (topHash[item.iso2]) {
        const option = topOptions.find((option) => option.iso2 == item.iso2)
        option.name = item.name
      } else {
        remainingOptions.push(item)
      }
    })
    let html = ''
    // let html = '<option value=""> Select Country </option>'
    // html += '<option value="">------------------------------</option>'
    topOptions.forEach((item) => (html += `<option value="${item.iso2}" > ${item.name} </option>`))
    // html += '<option value="">------------------------------</option>'
    remainingOptions.forEach((item) => (html += `<option value="${item.iso2}" > ${item.name} </option>`))
    const countrySelect = this.getInputFromName('country')
    countrySelect.innerHTML = html
  }

  populateStates() {
    const countryValue = this.store.get().country
    const statesSelect = this.getInputFromName('state')
    let html = ''
    const countryData = Checkout.allCountries.find((country) => countryValue == country.iso2)
    countryData.regions.forEach((region, index) => {
      html += `<option value="${region.state_code}" > ${region.name} </option>`
    })
    const firstStateValue = countryData.regions?.[0]?.state_code
    if (firstStateValue) {
      $(statesSelect).parents('.elFormItemWrapper').show()
      statesSelect.innerHTML = html
    } else {
      $(statesSelect).parents('.elFormItemWrapper').hide()
      statesSelect.innerHTML = ''
    }
  }

  stateMap(countryValue) {
    const stateMap = {
      mapByCode: {},
      mapByGoogleName: {},
    }

    const countryData = Checkout.allCountries.find((country) => countryValue == country.iso2)

    countryData.regions.forEach((region, index) => {
      stateMap.mapByCode[region.state_code] = true
      stateMap.mapByGoogleName[region.google_name] = region.state_code
    })

    return stateMap
  }

  firstStateFromCountry(countryValue) {
    const countryData = Checkout.allCountries.find((country) => country.iso2 == countryValue)
    return countryData.regions?.[0]?.state_code
  }

  enableBrowserAutoCompleteWhenBlank(autoCompleteLsr) {
    const addressInput = this.getInputFromName('address')
    const autoCompleteValue = addressInput.getAttribute('autocomplete')
    const observer = new MutationObserver(function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.attributeName == 'autocomplete' && mutation.target.getAttribute('autocomplete') == 'off') {
          observer.disconnect();
          if (mutation.target.value?.length) {
            mutation.target.setAttribute('autocomplete', 'none');
          } else {
            mutation.target.setAttribute('autocomplete', autoCompleteValue);
          }
        }
      }
    })
    observer.observe(addressInput, { attributes: true });

    addressInput.addEventListener('input', (e) => {
      if (e.target.value?.length) {
        e.target.setAttribute('autocomplete', 'none');
      } else {
        e.target.setAttribute('autocomplete', autoCompleteValue);
      }
    })

    addressInput.addEventListener('blur', (e) => {
      window.prevCheckoutAddressFocus = addressInput
      setTimeout(() => {
        if ($(addressInput).hasClass('gm-err-autocomplete')) {
         if (window.google.maps.event) {
           window.google.maps.event.removeListener(this.autoCompleteLsr)
           window.google.maps.event.clearInstanceListeners(addressInput)
         }
       }
      })
    })
  }



}

window["CheckoutAddressFormV1"] = CheckoutAddressFormV1

