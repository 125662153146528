
import "./checkout-submit-notification-v1"
import "./checkout-apple-pay-button-v1"
import { CheckoutSubmit } from "@yggdrasil/Checkout/V2/Services/checkout-submit"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutExpressPaymentsV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



    devices = {
      'apple-pay': undefined,
    }

    mount () {
      this.enabledPayments = this.enabledPayments ?? []
      this.cleanupApplePayOption()
      const form = document.querySelector('#cfAR')
      form.querySelectorAll('input[data-rebilly]').forEach((cfARInput) => {
        const dataRebillyAttribute = cfARInput.getAttribute('data-rebilly')
        const dataCfFormField = cfARInput.getAttribute('data-cf-form-field')
        const pageInputField = document.querySelector('input' + '[name="' + dataCfFormField + '"]')
        if (!pageInputField) {
          cfARInput?.removeAttribute('data-rebilly')
        } else {
          pageInputField?.setAttribute('data-rebilly', dataRebillyAttribute)
        }
      })

      Checkout.computed.modeLeaveEnterEvent.subscribe(ev => {
        const { leave, next, enter, previous } = ev
        const paymentState = Checkout.store.payment.state.get()
        if (paymentState == Checkout.PaymentStates.INITIALIZED) {
          if (leave && leave === this.mode) {
            this.unmountAllPaymentMethods()
          }
          if (enter === this.mode && previous) {
            this.mountExpressRebillyElements()
          }
        }
      })

      Checkout.store.payment.state.listen((state) => {
        const checkoutMode = Checkout.store.checkout.mode.get()
        switch (state) {
          case Checkout.PaymentStates.LOADING: {
            if (this.mode == 'guest') this.showLoader()
            break
          }
          case Checkout.PaymentStates.INITIALIZED: {
            if (this.mode == 'guest') this.hideLoader()
            this.element.setAttribute('data-initialized', 'true')
            this.unmountAllPaymentMethods()
            if (this.mode == Checkout.store.checkout.mode.get()) this.mountExpressRebillyElements()
            break
          }
        }
      })
    }

    cleanupApplePayOption() {
      const applePayIndex = this.enabledPayments.findIndex((v) => v == 'apple-pay')
      if (!window.ApplePaySession && applePayIndex >= 0) {
        this.enabledPayments.splice(applePayIndex, 1)
      }

      const applePayElement = this.element.querySelector('.apple-pay-button-wrapper')
      if (!applePayElement) return

      if (!window.ApplePaySession || !this.enabledPayments.includes('apple-pay')) {
        const isApplePayActive = applePayElement.getAttribute('data-active') == 'true'
        applePayElement.remove()
        if (this.enabledPayments.length <= 1) {
          this.element.querySelector('.pai-express-payment-methods')?.remove()
          this.element.querySelector('.elCheckoutExpressPaymentSeperator')?.remove()
        }
      }
    }

    showLoader() {
      this.element.querySelector('.elPAILoader').dataset.loader = true
    }
    hideLoader() {
      this.element.querySelector('.elPAILoader').dataset.loader = false
    }

    unmountPaymentMethod(type) {
      switch (type) {
        case 'apple-pay': {
          if (!this.devices['apple-pay']) break
          this.devices['apple-pay'].unmount()
          this.devices['apple-pay'] = undefined
          break
        }
      }
    }

    unmountPaymentMethods(types) {
      types.forEach((type) => {
        this.unmountPaymentMethod(type)
      })
    }

    unmountAllPaymentMethods() {
      this.unmountPaymentMethods(this.enabledPayments)
    }

    mountExpressRebillyElements() {
      const Rebilly = globalThis.Rebilly

      Object.values(this.enabledPayments).forEach((paymentType) => {
        if ((paymentType == 'apple-pay') && !this.devices['apple-pay']) {
          this.devices['apple-pay'] = Rebilly.applePay.mount(`[data-wrapper-checkout-state="${this.mode}"] .elExpressPaymentMethods .apple-pay-button`)
        }
      })
    }



}

window["CheckoutExpressPaymentsV1"] = CheckoutExpressPaymentsV1

