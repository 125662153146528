

import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutSubmitNotificationV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    Checkout.store.submitting.listen((submitting) => {
      const state = submitting.state
      switch (state) {
        case Checkout.SubmittingStates.IDLE: {
          this.setStatus("", "")
          break;
        }
        case Checkout.SubmittingStates.START: {
          this.setStatus("", "")
          break;
        }
        case Checkout.SubmittingStates.WAITING_ON_QUEUE: {
          this.setStatus('Waiting on queue', `(Retrying in ${submitting.remainingSeconds}s)`)
          break;
        }
        case Checkout.SubmittingStates.ERROR: {
          switch (submitting.code) {
            case Checkout.ErrorTypes.REBILLY_ERROR:
            case Checkout.ErrorTypes.SERVER_ERROR:
            case Checkout.ErrorTypes.UNHANDLED_SERVER_RESPONSE:
            case Checkout.ErrorTypes.EXCEEDED_MAX_RETRIES: {
              this.setStatus('Failed to submit', 'Retry again in a few seconds', submitting.message ? submitting.message : "")
              break;
            }
            case Checkout.ErrorTypes.THREEDS_DECLINED_ERROR: {
              this.setStatus('Failed to submit', 'Transaction declined')
              break;
            }
            case Checkout.ErrorTypes.SERVER_ERROR_WITH_MESSAGE: {
              this.setStatus('Failed to submit', submitting.message)
              break;
            }
            case Checkout.ErrorTypes.THREEDS_DECLINED_CUSTOM_ERROR: {
              this.setStatus('Failed to submit', submitting.message)
              break;
            }
          }
          break;
        }
        case Checkout.SubmittingStates.DONE: {
          break;
        }
      }
    })
  }

  setStatus(text, subtext, extratext = "") {
    if (text) {
      this.element.querySelector('.mainText').classList.remove('elHide')
      this.element.querySelector('.mainText').innerHTML = text
    } else {
      this.element.querySelector('.mainText').classList.add('elHide')
    }
    if (subtext) {
      this.element.querySelector('.subText').classList.remove('elHide')
      this.element.querySelector('.subText').innerHTML = subtext
    } else {
      this.element.querySelector('.subText').classList.add('elHide')
    }
    if (extratext) {
      this.element.querySelector('.extraText').classList.remove('elHide')
      this.element.querySelector('.extraText').innerHTML = extratext
    } else {
      this.element.querySelector('.extraText').classList.add('elHide')
    }
  }



}

window["CheckoutSubmitNotificationV1"] = CheckoutSubmitNotificationV1

