

import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutThreedsFrameV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    Checkout.store.threeds.listen((threeds) => {
      if (threeds.show) {
        const approvalUrl = threeds.approvalUrl
        const iframe = document.createElement('iframe')
        const orderStautsIframe = '/cf_order_status?disable-dispatch=true'
        iframe.onload = (evt) => {
          if (evt.target.src.includes(orderStautsIframe)) {
            this.element.classList.remove('elHide')
            iframe.src = approvalUrl
          }  
        }
        this.element.innerHTML = ''
        this.element.appendChild(iframe)
        iframe.src = orderStautsIframe
      } else {
        this.element.classList.add('elHide')
        this.element.innerHTML = ''
      }
    })
  }



}

window["CheckoutThreedsFrameV1"] = CheckoutThreedsFrameV1

