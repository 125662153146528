export type MultistepFormStructure = Record<number, string[][]>

export type MultiStepFormBySteps = Record<number, Record<string, number>>

export function formBySteps(formStructure: MultistepFormStructure): MultiStepFormBySteps {
  return Object.entries(formStructure).reduce((acc1, item) => {
    const [totalSteps, form] = item
    acc1[totalSteps] = {}
    form.reduce((acc2, form, index) => {
      form.forEach((field) => {
        acc2[field] = index
      })
      return acc2
    }, acc1[totalSteps])
    return acc1
  }, {})
}
