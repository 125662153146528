import { CardEvent } from '../types'
import { validateValue } from '../Utils/checkout-input-validator'
import { ERROR_CODES } from './checkout-critical-errors'
import { PaymentMethodType, ContactFields, AddressFields } from '../types'
import { atom, map, onMount, computed } from 'nanostores'
import { buildProductSelectComputed } from '../CheckoutProductSelect/V2/checkoutProductSelect'
import { cleanupEmptyErrors } from '../RebillyCheckout/checkout-globals'

type CheckoutStore = any
type CheckoutComputed = any

const getInitialCriticalErrors = (
  enabledPayments: PaymentMethodType[],
  enabledExpressPayments: PaymentMethodType[]
) => {
  const initialCriticalErrors = []
  if ((enabledPayments ?? []).length == 0 && (enabledExpressPayments ?? []).length == 0) {
    initialCriticalErrors.push({ code: ERROR_CODES.EMPTY_PAYMENT_METHODS_ERROR })
  }
  if (!globalThis.globalResourceData.productVariantWithNoBump?.id) {
    initialCriticalErrors.push({ code: ERROR_CODES.EMPTY_PRODUCTS_ERROR })
  }
  return initialCriticalErrors
}

export const create = (
  billingFields: AddressFields,
  shippingFields: AddressFields,
  enabledPayments: PaymentMethodType[],
  enabledExpressPayments: PaymentMethodType[]
): any => {
  const paymentMethods =
    typeof globalThis.Checkout.savedPaymentMethods === 'string'
      ? JSON.parse(globalThis.Checkout.savedPaymentMethods)
      : globalThis.Checkout.savedPaymentMethods
  const paymentDataId = paymentMethods?.[0]?.id ?? null
  const {
    mode,
    contact,
    shipping,
    billing,
    billingSameAsShipping,
    billing_addresses,
    shipping_addresses,
  } = globalThis.Checkout.ssrDynamicData

  return {
    state: atom(globalThis.Checkout.StoreStates.START),
    checkout: {
      mode: atom(mode),
      lastModeIndependentOfCartItems: atom(mode),
      step: atom(0),
    },
    contact: map(contact),
    contact_pending_auth: map({ authenticated: false }),
    shipping: map(shipping),
    shipping_addresses: atom(shipping_addresses ?? []),
    loadingShipping: atom(false),
    shippingOptions: atom([]),
    isUpdatingRebilly: atom(true),
    shippingOption: atom(),
    billingFields: atom(billingFields),
    shippingFields: atom(shippingFields),
    payment: {
      state: atom(globalThis.Checkout.PaymentStates.START),
      id: atom(paymentDataId),
      type: atom(enabledPayments[0]),
      'payment-card': {
        events: map({}),
        token: atom(null),
      },
      paypal: {
        state: atom({ state: globalThis.Checkout.PaypalStates.IDLE }),
        token: atom(null),
      },
      'apple-pay': {
        token: atom(null),
      },
    },
    paymentMethods: atom(paymentMethods ?? []),
    billing: map(billing),
    billingApiErrorsByField: atom(null),
    billing_addresses: atom(billing_addresses ?? []),
    billingSameAsShipping: atom(billingSameAsShipping),
    coupons: {
      appliedCode: atom(''),
      currentCode: atom(''),
      errorMessage: atom(''),
      state: atom(globalThis.Checkout.CouponStates.READY),
    },
    summary: atom({
      state: globalThis.Checkout.SummaryStates.WAITING,
      data: {},
    }),
    tos: {
      accepted: atom(false),
      present: atom(false),
    },
    criticalErrors: atom(getInitialCriticalErrors(enabledPayments, enabledExpressPayments)),
    showAllErrors: {
      contact: atom(false),
      shipping: atom(false),
      billing: atom(false),
      shippingOption: atom(false),
      payment: atom(false),
      products: atom(false),
      tos: atom(false),
    },
    submitting: atom({
      state: globalThis.Checkout.SubmittingStates.IDLE,
    }),
    incrScrollToFirstVisibleError: atom(0),
    threeds: map({
      show: false,
      approvalUrl: null,
    }),
    featureFlags: {
      isShippingEnabled: atom(false),
      isCouponEnabled: atom(false),
    },
    selectedOrderDetailId: atom(null),
    phoneNumberInitialized: atom(false),
  }
}

export const buildComputed = (
  store: CheckoutStore,
  contactFields: ContactFields,
  shippingFields: AddressFields,
  totalSteps: number
): CheckoutComputed => {
  const computedModeLeaveEnterEvent = atom({})
  onMount(computedModeLeaveEnterEvent, () => {
    let previousMode
    return store.checkout.mode.subscribe((mode) => {
      if (previousMode) {
        computedModeLeaveEnterEvent.set({
          leave: previousMode,
          next: mode,
        })
      }
      computedModeLeaveEnterEvent.set({
        enter: mode,
        previous: previousMode,
      })
      previousMode = mode
    })
  })

  const computedContactErrors = computed(
    [store.checkout.mode, store.contact, store.showAllErrors.contact],
    (mode, contact, showAllErrors) => {
      const errors = {
        globalErrors: [],
        fields: {},
      }
      // NOTE: contact form should only be validated in guest mode.
      // In all other modes its currently disabled.
      if (mode != globalThis.Checkout.CheckoutStates.GUEST) return null

      // NOTE: need to do something here
      contactFields.forEach((field) => {
        const value = contact[field]
        if (value == undefined && !showAllErrors) return

        const { valid, message } = validateValue(field, value)
        if (!valid) {
          errors.fields[field] = { message }
        }
      })
      return cleanupEmptyErrors(errors)
    }
  )

  const computedCheckoutCart = buildProductSelectComputed()

  const computedBillingErrors = computed(
    [
      store.billing,
      store.billingSameAsShipping,
      store.showAllErrors.billing,
      computedCheckoutCart,
      store.checkout.mode,
      store.payment.type,
      store.payment.id,
      store.billingApiErrorsByField,
      store.billingFields,
    ],
    (
      billing,
      billingSameAsShipping,
      showAllErrors,
      cart,
      mode,
      paymentType,
      paymentId,
      billingApiErrorsByField,
      billingFields
    ) => {
      return globalThis.Checkout.utils.billingErrors(
        billing,
        billingSameAsShipping,
        showAllErrors,
        cart,
        mode,
        billingFields,
        billingApiErrorsByField,
        paymentType,
        paymentId
      )
    }
  )

  const computedShippingErrors = computed(
    [store.shipping, store.showAllErrors.shipping, computedCheckoutCart, store.checkout.mode, store.payment.type],
    (shipping, showAllErrors, cart, mode, paymentType) => {
      return globalThis.Checkout.utils.shippingErrors(shipping, showAllErrors, cart, mode, shippingFields, paymentType)
    }
  )

  const computedHasValidShippingAddress = computed(
    [computedCheckoutCart, store.shipping, store.checkout.mode],
    (cart, shipping, mode) => {
      return !globalThis.Checkout.utils.shippingErrors(shipping, true, cart, mode, shippingFields)
    }
  )

  const computedProductErrors = computed(
    [computedCheckoutCart, store.showAllErrors.products],
    (cart, showAllErrors) => {
      return globalThis.Checkout.utils.productErrors(cart, showAllErrors)
    }
  )

  const computedShippingOptionErrors = computed(
    [
      store.loadingShipping,
      computedCheckoutCart,
      store.shippingOptions,
      store.shippingOption,
      store.showAllErrors.shippingOption,
      store.payment.type,
    ],
    (loadingShipping, cart, shippingOptions, shippingOption, showAllErrors, paymentType) => {
      const errors = {
        globalErrors: [],
        fields: {} as any,
      }
      if (
        !showAllErrors ||
        loadingShipping ||
        !globalThis.Checkout.utils.hasPhysicalProductsWithParams(cart) ||
        paymentType == 'apple-pay'
      ) {
        return null
      }
      const shippingOptionIsValid = shippingOption && shippingOptions?.length > 0
      if (!shippingOptionIsValid) {
        const error = { message: 'Shipping is not available for this location' }
        errors.fields.shippingOption = error
        errors.globalErrors.push(error)
      }
      return cleanupEmptyErrors(errors)
    }
  )

  const computedPaymentErrors = computed(
    [
      store.payment.id,
      store.payment.type,
      store.payment['payment-card'].events,
      store.payment.paypal.state,
      store.payment.paypal.token,
      store.showAllErrors.payment,
      store.checkout.mode,
    ],
    (id, type, cardEvents: Record<string, CardEvent>, paypalState, paypalToken, showAllErrors, mode) => {
      const errors = {
        globalErrors: [],
        fields: {},
      }
      if (!id && mode != globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE) {
        if (type == 'payment-card') {
          let hasEvent = !!Object.keys(cardEvents).length
          if (hasEvent) {
            Object.entries(cardEvents).forEach(([field, event]) => {
              if (!event) {
                event = { source: field }
              } else {
                hasEvent = true
              }
              const { valid, message } = validateValue('card', event)
              if (!valid) {
                errors.fields[field] = { message }
              }
            })
          } else if (showAllErrors) {
            errors.globalErrors.push({ message: 'Missing payment information' })
          }
        } else if (type == 'paypal') {
          if (
            showAllErrors &&
            !(paypalState.state == globalThis.Checkout.PaypalStates.PAYMENT_METHOD_APPROVED && paypalToken)
          ) {
            errors.globalErrors.push({ message: 'Missing payment information' })
          }
        } else {
          if (showAllErrors) errors.globalErrors.push({ message: 'Missing payment information' })
        }
      }
      return cleanupEmptyErrors(errors)
    }
  )

  const computedTosErrors = computed(
    [store.tos.accepted, store.tos.present, store.showAllErrors.tos],
    (accepted, present, showTosErrors) => {
      if (present && showTosErrors && !accepted) {
        return { globalErrors: [{ message: 'You must accept the terms of service' }] }
      } else {
        return []
      }
    }
  )

  const computedErrorsByName = {
    contact: computedContactErrors,
    billing: computedBillingErrors,
    shipping: computedShippingErrors,
    shippingOption: computedShippingOptionErrors,
    products: computedProductErrors,
    payment: computedPaymentErrors,
    tos: computedTosErrors,
  }

  const computedHasPhysicalProducts = computed(computedCheckoutCart, (cart) => {
    return globalThis.Checkout.utils.hasPhysicalProductsWithParams(cart)
  })

  const computedPossibleOtoWithFreeShipping = computed(
    [computedCheckoutCart, store.checkout.mode, store.shippingOptions, store.loadingShipping],
    (cart, mode, shippingOptions, loadingShipping) => {
      return (
        mode === 'oto' &&
        globalThis.Checkout.utils.hasPhysicalProductsWithParams(cart) &&
        // shipping options unknown / not loaded
        (!shippingOptions ||
          // pending with no current shipping options
          (loadingShipping && shippingOptions.length === 0) ||
          // Exactly one shipping option with amount zero
          (shippingOptions?.length === 1 && parseInt(shippingOptions[0].amount.amount) === 0))
      )
    }
  )

  const computedHasShippingEnabled = computed(
    [computedCheckoutCart, store.featureFlags.isShippingEnabled],
    (cart, isShippingEnabled) => {
      return globalThis.Checkout.utils.hasPhysicalProductsWithParams(cart) && isShippingEnabled
    }
  )

  const computedHideShipping = computed(
    [computedHasShippingEnabled, computedPossibleOtoWithFreeShipping],
    (isShippingEnabled, possibleOtoWithFreeShipping) => {
      return !isShippingEnabled || possibleOtoWithFreeShipping
    }
  )

  const computedCurrentPaymentMethod = computed([store.payment.id], (currentPaymentId) => {
    return store.paymentMethods.get().find((pm) => pm.id == currentPaymentId)
  })

  const computedIsCouponReady = computed([store.state, computedCheckoutCart], (state, cart) => {
    const showAllProductErrors = true

    const pErrors = globalThis.Checkout.utils.productErrors(cart, showAllProductErrors)
    return state == globalThis.Checkout.StoreStates.FILLING_FORM && !globalThis.Checkout.utils.hasErrors(pErrors)
  })

  const computedIsDigitalWalletReadyToStart = computed(
    [store.checkout.mode, store.state, store.summary, computedCheckoutCart],
    (mode, state, summary, cart) => {
      const showAllProductErrors = true
      const pErrors = globalThis.Checkout.utils.productErrors(cart, showAllProductErrors)
      return state == globalThis.Checkout.StoreStates.FILLING_FORM && !globalThis.Checkout.utils.hasErrors(pErrors)
    }
  )

  const computedHideContactInformationForm = computed(
    [store.checkout.mode, store.payment.type],
    (mode, paymentType) => {
      return paymentType == 'apple-pay' && mode == 'guest' && totalSteps == 1
    }
  )

  const computedIsOTORetrialPayment = computed(
    [store.checkout.mode, store.payment.id],
    (mode, paymentId) => mode == globalThis.Checkout.CheckoutStates.OTO && !paymentId
  )

  const computedUseDigitalWalletForUpdatingContactStore = computed([store.checkout.mode], (mode) => mode == 'guest')

  return {
    errorsByName: computedErrorsByName,
    modeLeaveEnterEvent: computedModeLeaveEnterEvent,
    hasPhysicalProducts: computedHasPhysicalProducts,
    hasShippingEnabled: computedHasShippingEnabled,
    hideShipping: computedHideShipping,
    hasValidShippingAddress: computedHasValidShippingAddress,
    isDigitalWalletReadyToStart: computedIsDigitalWalletReadyToStart,
    isCouponReady: computedIsCouponReady,
    contactErrors: computedContactErrors,
    productErrors: computedProductErrors,
    billingErrors: computedBillingErrors,
    shippingErrors: computedShippingErrors,
    shippingOptionErrors: computedShippingOptionErrors,
    paymentMethod: computedCurrentPaymentMethod,
    paymentErrors: computedPaymentErrors,
    tosErrors: computedTosErrors,
    hideContactInformationForm: computedHideContactInformationForm,
    useDigitalWalletForUpdatingContactStore: computedUseDigitalWalletForUpdatingContactStore,
    isOTORetrialPayment: computedIsOTORetrialPayment,
    isNewDigitalWalletPayment: computed(
      [store.payment.id, store.payment.type],
      (id, paymentType) => !id && paymentType == 'apple-pay'
    ),
    checkoutCart: computedCheckoutCart,
  }
}

export const initializeUtilities = (store: CheckoutStore, computed: CheckoutComputed): void => {
  initializeStoreUtilities(store, computed)
  setupCFEnableDevTools()
}

const initializeStoreUtilities = (store: CheckoutStore, computed: CheckoutComputed): void => {
  const proxyCache = new WeakMap()
  const storeValueCache = new WeakMap()
  const DEBOUNCE_INTERVAL = 50

  const stores = {
    store,
    computed,
  }

  function createDeepNanostoreProxy(target, onChange) {
    return new Proxy(target, {
      get(target, property) {
        const item = target[property]
        if (property === '__store') {
          return item
        }
        if (item && typeof item === 'object') {
          if (proxyCache.has(item)) return proxyCache.get(item)
          if (isStore(item)) {
            // instead of proxy, return value holder
            const __store = item
            const storeValue = {
              __store,
              __value: peek(__store),
            }
            storeValueCache.set(item, storeValue)
            __store.listen((value) => {
              storeValue.__value = value
              onChange()
            })
            return storeValue
          } else {
            const proxy = createDeepNanostoreProxy(item, onChange)
            proxyCache.set(item, proxy)
            return proxy
          }
        }
        return item
      },
    })
  }

  function peek(store) {
    return store.value
  }
  function isStore(value) {
    return value && value.subscribe
  }

  let debounceTimeout
  const proxy = createDeepNanostoreProxy(stores, function onChange() {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => {
      window.dispatchEvent(new CustomEvent('checkout:store:change'))
    }, DEBOUNCE_INTERVAL)
  })

  /**
   * Stringify the whole proxy
   * Replace stores with their current values
   */
  globalThis.Checkout.utils.getStoreJson = (tab = 0) => {
    return JSON.stringify(
      proxy,
      function replacer(key, value: any) {
        // prevent circular references breaking json
        if (['__store'].includes(key)) return
        if (value && Object.prototype.hasOwnProperty.call(value, '__value')) {
          return value.__value
        }
        return value
      },
      tab
    )
  }
}

const setupCFEnableDevTools = () => {
  if (localStorage.getItem('cf2:devtools:enabled')) {
    trackUpdatesCheckoutUpdates()
  }
  globalThis.CFEnableDevTools = () => {
    localStorage.setItem('cf2:devtools:enabled', 'true')
    trackUpdatesCheckoutUpdates()
  }
  function trackUpdatesCheckoutUpdates() {
    document.querySelectorAll('[data-page-element="Checkout/V2"]').forEach((el) => {
      el.insertAdjacentHTML('afterend', '<div class="checkout-store-log"><pre></pre></div>')
      document.querySelector<HTMLElement>('.checkout-store-log').style.cssText = `
          z-index: 10;
          top: 0;
          background: rgba(100,100,1005,0.1);
          height: 100;
          display: flex;
          flex-direction: column;
          justify-content: center;`
      document.querySelector<HTMLElement>('.checkout-store-log pre').style.cssText = `
          font-size: 12px;
          font-weight: bold;
          width: fit-content;`
    })
    const checkoutStoreLog = document.querySelector('.checkout-store-log')

    const renderDevToolsState = initReduxDevTools().render
    render()

    window.addEventListener('checkout:store:change', () => {
      render()
      if (renderDevToolsState) {
        renderDevToolsState()
      }
    })

    /**
     * Lazily load Redux and create a store
     * When proxy changes, dispatch state updates to store
     * Send clone by stringifying and then parsing JSON
     */

    function initReduxDevTools() {
      let store

      // inject redux
      const reduxUrl = 'https://cdnjs.cloudflare.com/ajax/libs/redux/4.2.1/redux.min.js'
      const scriptEle = document.createElement('script')
      scriptEle.setAttribute('src', reduxUrl)
      document.body.appendChild(scriptEle)

      // on load, create debug store
      scriptEle.addEventListener('load', () => {
        store = globalThis.Redux.createStore(
          (state, action) => action.state || state,
          JSON.parse(globalThis.Checkout.utils.getStoreJson()),
          globalThis.__REDUX_DEVTOOLS_EXTENSION__ && globalThis.__REDUX_DEVTOOLS_EXTENSION__()
        )
      })

      return {
        render() {
          // render callback for proxy change handler to call
          if (store) {
            store.dispatch({
              type: 'state',
              state: JSON.parse(globalThis.Checkout.utils.getStoreJson()),
            })
          }
        },
      }
    }

    /**
     * Render JSON to div on change
     */
    function render() {
      let json
      let error
      try {
        const data = JSON.parse(globalThis.Checkout.utils.getStoreJson(4))
        json = JSON.stringify(data, null, 2)
      } catch (err) {
        error = err
        console.log(error)
      }
      checkoutStoreLog.querySelector('pre').innerHTML = error ? error.message : ['--- State ---', json].join('\n')
    }
  }
}
